<template lang="pug">
om-modal.agency-send-report-modal(:name="name" @beforeOpen="loadLoginEmails")
  template(#modal-header)
    .row
      .col
        .title.text-left {{ $t('affiliate.sendReport') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide(name)" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(#modal-body)
    .d-flex.flex-column.mt-2
      .row
        .col-4 {{ $t('agencyReport.dateRange') }}
        .col-8
          om-date-time#_id.mr-2(
            mode="range"
            :locale="getLocale"
            :maxDate="maxDate"
            :minDate="minDate"
            @datetime="onDateChange"
            :error="($v.fromDate.$error && !$v.fromDate.required) || ($v.toDate.$error && !$v.toDate.required)"
          )
    .d-flex.flex-column.mt-2
      .row
        .col-4 {{ $t('agencyReport.emails') }}
        .col-8
          multiselect(
            v-model="emails"
            label="value"
            track-by="key"
            :multiple="true"
            :selectedLabel="$t('')"
            :select-label="$t('')"
            :options="loginEmails"
            :class="{ error: $v.emails.$error && !$v.emails.required }"
          )
    .d-flex.justify-content-end.mt-5
      om-button(primary @click="sendUsageReportByDate") {{ $t('affiliate.sendReport') }}
</template>
<script>
  import GET_LOGIN_EMAILS from '@/graphql/GetLoginEmails.gql';
  import SEND_AGENCY_REPORT_BY_DATE from '@/graphql/SendAgencyReportByDate.gql';
  import moment from 'moment';
  import { required } from 'vuelidate/lib/validators';
  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'AgencySendReport',
    data() {
      return {
        name: 'agency-send-report',
        minDate: moment().subtract(3, 'month').toDate(),
        maxDate: new Date(),
        fromDate: null,
        toDate: null,
        emails: [],
        loginEmails: [],
      };
    },
    validations: {
      fromDate: {
        required,
      },
      toDate: {
        required,
      },
      emails: {
        required,
      },
    },
    computed: {
      ...mapState(['account']),
      ...mapGetters(['getLocale']),
    },
    methods: {
      async loadLoginEmails() {
        try {
          const {
            data: { getLoginEmails },
          } = await this.$apollo.query({
            query: GET_LOGIN_EMAILS,
          });

          this.loginEmails = getLoginEmails.map((email) => ({ key: email, value: email }));
        } catch (e) {
          console.error(e);
        }
      },
      onDateChange([start, end]) {
        if (!start || !end) {
          return;
        }

        this.fromDate = moment(start).format('YYYY-MM-DD');
        this.toDate = moment(end).format('YYYY-MM-DD');
      },
      async sendUsageReportByDate() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        try {
          const {
            data: { data },
          } = await this.$apollo.query({
            query: SEND_AGENCY_REPORT_BY_DATE,
            variables: {
              fromDate: this.fromDate,
              toDate: this.toDate,
              emails: this.emails.map((email) => email.value),
            },
          });

          this.notify(data);
        } catch (e) {
          this.$notify({
            type: 'error',
            title: this.$t('notifications.unsuccessfulUsageReport'),
          });
        }
      },

      notify(data) {
        let title = '?';

        if (data.success) {
          title = this.$t('notifications.sendSuccess');
        } else if (data.errorCode === 'NO_DATA_IN_RANGE') {
          title = this.$t('notifications.noDataInRange');
        } else if (data.errorCode === 'LIMIT_REACHED') {
          title = this.$t('notifications.limitReached');
        }

        this.$notify({
          type: data.success ? 'success' : 'error',
          title,
        });
      },
    },
  };
</script>

<style lang="sass">
  .agency-send-report-modal
    .brand-modal-body .multiselect
      min-height: 3.5rem !important
      max-height: 3.5rem !important

    .brand-modal-body .multiselect__tags-wrap
      max-height: 3.5rem

    .multiselect.error .multiselect__tags
      border-color: red !important
</style>
